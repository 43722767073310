













import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { GetReportModel, GetReportTemplateModel } from 'truemarket-modules/src/models/api/reports'
import CreateReportFromTemplateModalOptions from '@/app_code/Modals/Options/CreateReportFromTemplateModalOptions'
import { Services } from 'truemarket-modules/src/services'
import { DropdownListItem } from '@/app_code/Forms'

@Component
export default class CreateReportFromTemplateModal extends Vue {
  @Prop()
  private options!: CreateReportFromTemplateModalOptions;

  private templateSelection = ''

  private templateOptions: DropdownListItem[] = []

  mounted () {
    this.updateTypes()
  }

  @Watch('options.ReportType')
  updateTypes () {
    if (!this.options || !this.options.ReportType) return

    Services.API.Reports.GetTemplates(this.options.ReportType).then((templates) => {
      this.templateOptions = templates.map((t) => new DropdownListItem(t.Id, t.Name, t.Id))
    })
  }

  createReport () {
    console.log(this.templateSelection)

    if (this.templateSelection) {
      Services.API.Reports.CreateFromTemplate({
        TypeId: this.options.TypeId!,
        Type: this.options.ReportType!,
        TemplateId: this.templateSelection
      }).then((report) => {
        if (this.options.Callback) this.options.Callback(report)

        this.$emit('close')
      })
    }
  }
}
